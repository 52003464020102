<template>
  <div class="side-bar">

    <div class="side-links">
      <ul>
        <li>
          <router-link
          v-if="is_company"
            to="/profile/home"
            :class="[$route.path == '/profile/home' ? 'active' : '']"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.44026 15.0003C2.52403 15.0003 1.78125 14.2381 1.78125 13.297V6.45092C1.78125 5.93369 2.0107 5.44369 2.40348 5.12091L6.96673 1.37355C7.25829 1.13211 7.62497 1 8.00352 1C8.38206 1 8.74874 1.13211 9.0403 1.37355L13.6028 5.12091C13.9963 5.44369 14.2258 5.93369 14.2258 6.45092V13.297C14.2258 14.2381 13.483 15.0003 12.5668 15.0003H3.44026Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.0625 15.0004V10.7226C6.0625 10.31 6.22639 9.91433 6.51812 9.62261C6.80984 9.33088 7.2055 9.16699 7.61807 9.16699H8.39585C8.80841 9.16699 9.20408 9.33088 9.4958 9.62261C9.78753 9.91433 9.95142 10.31 9.95142 10.7226V15.0004"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ $t("Home") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/profile/notifications"
            :class="[$route.path == '/profile/notifications' ? 'active' : '']"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 448 512" width="18" height="16" ><path d="M224 0c-17.7 0-32 14.3-32 32l0 19.2C119 66 64 130.6 64 208l0 18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416l384 0c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8l0-18.8c0-77.4-55-142-128-156.8L256 32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3l-64 0-64 0c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/></svg>
            <span>{{ $t("Notifications") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
           v-if="is_company"
            to="/profile/required-services"
            :class="[
              $route.path == '/profile/required-services' ? 'active' : '',
            ]"
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3 3.36842H12.6V2.52632C12.6 1.8563 12.3155 1.21372 11.8092 0.739941C11.3028 0.266165 10.6161 0 9.9 0H8.1C7.38392 0 6.69716 0.266165 6.19081 0.739941C5.68446 1.21372 5.4 1.8563 5.4 2.52632V3.36842H2.7C1.98392 3.36842 1.29716 3.63459 0.790812 4.10836C0.284464 4.58214 0 5.22472 0 5.89474V13.4737C0 14.1437 0.284464 14.7863 0.790812 15.2601C1.29716 15.7338 1.98392 16 2.7 16H15.3C16.0161 16 16.7028 15.7338 17.2092 15.2601C17.7155 14.7863 18 14.1437 18 13.4737V5.89474C18 5.22472 17.7155 4.58214 17.2092 4.10836C16.7028 3.63459 16.0161 3.36842 15.3 3.36842ZM7.2 2.52632C7.2 2.30298 7.29482 2.08878 7.4636 1.93086C7.63239 1.77293 7.8613 1.68421 8.1 1.68421H9.9C10.1387 1.68421 10.3676 1.77293 10.5364 1.93086C10.7052 2.08878 10.8 2.30298 10.8 2.52632V3.36842H7.2V2.52632ZM16.2 13.4737C16.2 13.697 16.1052 13.9112 15.9364 14.0691C15.7676 14.2271 15.5387 14.3158 15.3 14.3158H2.7C2.46131 14.3158 2.23239 14.2271 2.0636 14.0691C1.89482 13.9112 1.8 13.697 1.8 13.4737V8.8421C4.09163 9.69275 6.53462 10.1299 9 10.1305C11.4653 10.1291 13.9081 9.69197 16.2 8.8421V13.4737ZM16.2 6.9979C13.9306 7.93076 11.4788 8.41245 9 8.41245C6.52121 8.41245 4.06944 7.93076 1.8 6.9979V5.89474C1.8 5.6714 1.89482 5.4572 2.0636 5.29928C2.23239 5.14135 2.46131 5.05263 2.7 5.05263H15.3C15.5387 5.05263 15.7676 5.14135 15.9364 5.29928C16.1052 5.4572 16.2 5.6714 16.2 5.89474V6.9979Z"
                fill="white"
              />
            </svg>
            <span>{{ $t("Required_services") }}</span>
          </router-link>
        </li>
        <li >
          <router-link
          v-if="is_company"
            to="/profile/required-packages"
            :class="[
              $route.path == '/profile/required-packages' ? 'active' : '',
            ]"
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3 3.36842H12.6V2.52632C12.6 1.8563 12.3155 1.21372 11.8092 0.739941C11.3028 0.266165 10.6161 0 9.9 0H8.1C7.38392 0 6.69716 0.266165 6.19081 0.739941C5.68446 1.21372 5.4 1.8563 5.4 2.52632V3.36842H2.7C1.98392 3.36842 1.29716 3.63459 0.790812 4.10836C0.284464 4.58214 0 5.22472 0 5.89474V13.4737C0 14.1437 0.284464 14.7863 0.790812 15.2601C1.29716 15.7338 1.98392 16 2.7 16H15.3C16.0161 16 16.7028 15.7338 17.2092 15.2601C17.7155 14.7863 18 14.1437 18 13.4737V5.89474C18 5.22472 17.7155 4.58214 17.2092 4.10836C16.7028 3.63459 16.0161 3.36842 15.3 3.36842ZM7.2 2.52632C7.2 2.30298 7.29482 2.08878 7.4636 1.93086C7.63239 1.77293 7.8613 1.68421 8.1 1.68421H9.9C10.1387 1.68421 10.3676 1.77293 10.5364 1.93086C10.7052 2.08878 10.8 2.30298 10.8 2.52632V3.36842H7.2V2.52632ZM16.2 13.4737C16.2 13.697 16.1052 13.9112 15.9364 14.0691C15.7676 14.2271 15.5387 14.3158 15.3 14.3158H2.7C2.46131 14.3158 2.23239 14.2271 2.0636 14.0691C1.89482 13.9112 1.8 13.697 1.8 13.4737V8.8421C4.09163 9.69275 6.53462 10.1299 9 10.1305C11.4653 10.1291 13.9081 9.69197 16.2 8.8421V13.4737ZM16.2 6.9979C13.9306 7.93076 11.4788 8.41245 9 8.41245C6.52121 8.41245 4.06944 7.93076 1.8 6.9979V5.89474C1.8 5.6714 1.89482 5.4572 2.0636 5.29928C2.23239 5.14135 2.46131 5.05263 2.7 5.05263H15.3C15.5387 5.05263 15.7676 5.14135 15.9364 5.29928C16.1052 5.4572 16.2 5.6714 16.2 5.89474V6.9979Z"
                fill="white"
              />
            </svg>
            <span>{{ $t("Required_packages") }}</span>
          </router-link>
        </li>
        <li v-if="Is_Subscription">
          <router-link
            to="/profile/subscriptions"
            :class="[$route.path == '/profile/subscriptions' ? 'active' : '']"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.375 16.5H14.625C15.1223 16.5 15.5992 16.3025 15.9508 15.9508C16.3025 15.5992 16.5 15.1223 16.5 14.625V3.375C16.5 2.87772 16.3025 2.40081 15.9508 2.04917C15.5992 1.69754 15.1223 1.5 14.625 1.5H3.375C2.87772 1.5 2.40081 1.69754 2.04917 2.04917C1.69754 2.40081 1.5 2.87772 1.5 3.375V14.625C1.5 15.1223 1.69754 15.5992 2.04917 15.9508C2.40081 16.3025 2.87772 16.5 3.375 16.5Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M11.8125 2.4375V9L9 7.125L6.1875 9V2.4375"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ $t("My Subscriptions") }}</span>
          </router-link>
        </li>
        <li v-if="(is_company && individial) || (!is_company)" >
          <router-link
            to="/profile/individialRequests"
            :class="[$route.path == '/profile/individialRequests' ? 'active' : '']"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.375 16.5H14.625C15.1223 16.5 15.5992 16.3025 15.9508 15.9508C16.3025 15.5992 16.5 15.1223 16.5 14.625V3.375C16.5 2.87772 16.3025 2.40081 15.9508 2.04917C15.5992 1.69754 15.1223 1.5 14.625 1.5H3.375C2.87772 1.5 2.40081 1.69754 2.04917 2.04917C1.69754 2.40081 1.5 2.87772 1.5 3.375V14.625C1.5 15.1223 1.69754 15.5992 2.04917 15.9508C2.40081 16.3025 2.87772 16.5 3.375 16.5Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M11.8125 2.4375V9L9 7.125L6.1875 9V2.4375"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ $t("My Requests") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            @click="showSon = !showSon"
            to=""
            class="d-flex align-items-center"
            :class="[serviceActiveLink ? 'active' : '']"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.9235 0.604265C10.117 0.539867 10.3225 0.520122 10.5247 0.546514C10.7269 0.572906 10.9204 0.644747 11.0909 0.756648C11.2613 0.868548 11.4042 1.0176 11.5088 1.19262C11.6135 1.36764 11.6771 1.56408 11.6949 1.76719L11.7003 1.88601V6.82395L15.1153 7.50803C15.3999 7.56474 15.6586 7.71164 15.8532 7.92694C16.0477 8.14225 16.1677 8.4145 16.1954 8.70336L16.2008 8.83118V15.6629H17.1009C17.3303 15.6632 17.551 15.751 17.7178 15.9085C17.8846 16.066 17.985 16.2813 17.9985 16.5103C18.0119 16.7393 17.9374 16.9648 17.7901 17.1407C17.6429 17.3167 17.434 17.4297 17.2062 17.4568L17.1009 17.4631H0.899103C0.669685 17.4629 0.449024 17.375 0.282203 17.2175C0.115382 17.0601 0.0149931 16.8448 0.0015482 16.6158C-0.0118967 16.3868 0.062617 16.1612 0.209865 15.9853C0.357112 15.8094 0.565979 15.6963 0.793791 15.6692L0.899103 15.6629H1.7992V4.28567C1.7992 3.74561 2.11964 3.26316 2.60749 3.04894L2.7227 3.00483L9.9235 0.604265ZM9.9001 2.51068L3.5994 4.61061V15.6629H9.9001V2.51068ZM11.7003 8.66106V15.6629H14.4006V9.20022L11.7003 8.66106Z"
                fill="white"
              />
            </svg>
            <span> {{ $t("My Companies") }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <svg
              style="margin-inline-start: auto"
              :class="[showSon ? 'rotate' : '']"
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.853974 6.80652C0.627313 6.59066 0.5 6.29806 0.5 5.99298C0.5 5.68791 0.627313 5.3953 0.853974 5.17945L5.41783 0.837023C5.64489 0.621159 5.95281 0.499928 6.27384 0.5C6.4328 0.500036 6.5902 0.529851 6.73704 0.587743C6.88389 0.645635 7.01731 0.730471 7.12968 0.837407C7.24206 0.944342 7.33119 1.07128 7.39199 1.21098C7.45278 1.35068 7.48406 1.5004 7.48402 1.6516C7.48398 1.80279 7.45263 1.9525 7.39177 2.09217C7.3309 2.23184 7.24171 2.35874 7.12928 2.46562L3.42155 5.99298L7.13009 9.52034C7.24574 9.62649 7.33801 9.75348 7.40151 9.8939C7.46502 10.0343 7.49848 10.1854 7.49995 10.3382C7.50142 10.4911 7.47087 10.6427 7.41008 10.7842C7.3493 10.9257 7.25949 11.0543 7.1459 11.1624C7.03231 11.2706 6.89721 11.3561 6.74849 11.4141C6.59977 11.472 6.44041 11.5012 6.2797 11.5C6.11899 11.4987 5.96015 11.467 5.81245 11.4068C5.66475 11.3465 5.53115 11.2588 5.41945 11.1489L0.85236 6.80652H0.853974Z"
                fill="white"
              />
            </svg>
          </router-link>
        </li>
        <ul @click="showSon = !showSon" v-show="showSon" class="son-links">
          <li @click="showSon = !showSon">
            <router-link
              to="/profile/companies"
              :class="[$route.path == '/profile/companies' ? 'active' : '']"
            >
              <span>{{ $t("My Companies") }}</span>
            </router-link>
          </li>
          <li @click="showSon = !showSon">
            <router-link
            v-if="is_company"
              to="/profile/companies/managing-personnel"
              :class="[
                $route.path == '/profile/companies/managing-personnel'
                  ? 'active'
                  : '',
              ]"
            >
              {{ $t("Managing Company’s Personnel") }}
            </router-link>
          </li>
          <li @click="showSon = !showSon">
            <router-link
            v-if="is_company"
              to="/profile/companies/managing-roles"
              :class="[
                $route.path == '/profile/companies/managing-roles'
                  ? 'active'
                  : '',
              ]"
            >
              {{ $t("Managing Roles and Powers") }}
            </router-link>
          </li>
        </ul>
        <li>
          <router-link
          v-if="is_company"
            to="/profile/consulting"
            :class="[$route.path == '/profile/consulting' ? 'active' : '']"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9C1 6.87827 1.84285 4.84344 3.34315 3.34315C4.84344 1.84285 6.87827 1 9 1C11.1217 1 13.1566 1.84285 14.6569 3.34315C16.1571 4.84344 17 6.87827 17 9V14.09C17 14.938 17 15.36 16.874 15.699C16.7738 15.9676 16.617 16.2116 16.4143 16.4143C16.2116 16.617 15.9676 16.7738 15.699 16.874C15.36 17 14.937 17 14.09 17H9C6.87827 17 4.84344 16.1571 3.34315 14.6569C1.84285 13.1566 1 11.1217 1 9Z"
                stroke="white"
                stroke-width="2"
              />
              <path
                d="M6 8H12M9 12H12"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ $t("Consulting") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
          v-if="is_company"
            to="/profile/documents"
            :class="[$route.path == '/profile/documents' ? 'active' : '']"
          >
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1V8H17M3 5H2C1.73478 5 1.48043 5.10536 1.29289 5.29289C1.10536 5.48043 1 5.73478 1 6V18C1 18.2652 1.10536 18.5196 1.29289 18.7071C1.48043 18.8946 1.73478 19 2 19H11C11.2652 19 11.5196 18.8946 11.7071 18.7071C11.8946 18.5196 12 18.2652 12 18V17M5 2V14C5 14.2652 5.10536 14.5196 5.29289 14.7071C5.48043 14.8946 5.73478 15 6 15H16C16.2652 15 16.5196 14.8946 16.7071 14.7071C16.8946 14.5196 17 14.2652 17 14V7.389C17 7.13869 16.9062 6.89748 16.737 6.713L11.797 1.324C11.7033 1.22187 11.5894 1.14033 11.4625 1.08456C11.3357 1.02878 11.1986 0.999986 11.06 1H6C5.73478 1 5.48043 1.10536 5.29289 1.29289C5.10536 1.48043 5 1.73478 5 2Z"
                stroke="white"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </svg>
            <span>{{ $t("Documents") }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            to="/profile/my-account"
            :class="[$route.path == '/profile/my-account' ? 'active' : '']"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5714 4.5C11.5714 5.18199 11.3005 5.83604 10.8183 6.31827C10.336 6.80051 9.68199 7.07143 9 7.07143C8.31802 7.07143 7.66396 6.80051 7.18173 6.31827C6.69949 5.83604 6.42857 5.18199 6.42857 4.5C6.42857 3.81801 6.69949 3.16396 7.18173 2.68173C7.66396 2.19949 8.31802 1.92857 9 1.92857C9.68199 1.92857 10.336 2.19949 10.8183 2.68173C11.3005 3.16396 11.5714 3.81801 11.5714 4.5ZM13.5 4.5C13.5 5.69347 13.0259 6.83807 12.182 7.68198C11.3381 8.52589 10.1935 9 9 9C7.80653 9 6.66193 8.52589 5.81802 7.68198C4.97411 6.83807 4.5 5.69347 4.5 4.5C4.5 3.30653 4.97411 2.16193 5.81802 1.31802C6.66193 0.474106 7.80653 0 9 0C10.1935 0 11.3381 0.474106 12.182 1.31802C13.0259 2.16193 13.5 3.30653 13.5 4.5ZM1.92857 14.7857C1.92857 14.5234 2.21143 13.7456 3.62571 12.9099C4.93457 12.1371 6.84 11.5714 9 11.5714C11.16 11.5714 13.0654 12.1371 14.3743 12.9099C15.7886 13.7456 16.0714 14.5234 16.0714 14.7857C16.0714 15.1267 15.936 15.4537 15.6949 15.6949C15.4537 15.936 15.1267 16.0714 14.7857 16.0714H3.21429C2.87329 16.0714 2.54627 15.936 2.30515 15.6949C2.06403 15.4537 1.92857 15.1267 1.92857 14.7857ZM9 9.64286C4.05 9.64286 0 12.2143 0 14.7857C0 15.6382 0.338647 16.4558 0.941442 17.0586C1.54424 17.6614 2.3618 18 3.21429 18H14.7857C15.6382 18 16.4558 17.6614 17.0586 17.0586C17.6614 16.4558 18 15.6382 18 14.7857C18 12.2143 13.95 9.64286 9 9.64286Z"
                fill="white"
              />
            </svg>
            <span> {{ $t("My Account") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/profile/support"
            :class="[$route.path == '/profile/support' ? 'active' : '']"
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7953 0.280029H2.20808C1.79858 0.280029 1.40586 0.4427 1.1163 0.732256C0.826747 1.02181 0.664076 1.41453 0.664076 1.82403V14.176C0.662843 14.4701 0.746358 14.7582 0.904628 15.006C1.0629 15.2539 1.28922 15.4508 1.55651 15.5733C1.76166 15.6695 1.98537 15.7196 2.21194 15.72C2.5745 15.7192 2.92507 15.5901 3.20164 15.3556L3.2109 15.3472L5.64116 13.2496H15.7953C16.2048 13.2496 16.5975 13.087 16.887 12.7974C17.1766 12.5078 17.3393 12.1151 17.3393 11.7056V1.82403C17.3393 1.41453 17.1766 1.02181 16.887 0.732256C16.5975 0.4427 16.2048 0.280029 15.7953 0.280029ZM15.4865 11.3968H5.29607C5.07373 11.3964 4.85875 11.4765 4.69083 11.6223L2.51688 13.499V2.13283H15.4865V11.3968ZM5.60487 5.22083C5.60487 4.97513 5.70248 4.7395 5.87621 4.56577C6.04994 4.39203 6.28558 4.29443 6.53127 4.29443H11.4721C11.7178 4.29443 11.9534 4.39203 12.1271 4.56577C12.3009 4.7395 12.3985 4.97513 12.3985 5.22083C12.3985 5.46653 12.3009 5.70216 12.1271 5.87589C11.9534 6.04963 11.7178 6.14723 11.4721 6.14723H6.53127C6.28558 6.14723 6.04994 6.04963 5.87621 5.87589C5.70248 5.70216 5.60487 5.46653 5.60487 5.22083ZM5.60487 8.30883C5.60487 8.06313 5.70248 7.8275 5.87621 7.65377C6.04994 7.48003 6.28558 7.38243 6.53127 7.38243H11.4721C11.7178 7.38243 11.9534 7.48003 12.1271 7.65377C12.3009 7.8275 12.3985 8.06313 12.3985 8.30883C12.3985 8.55453 12.3009 8.79016 12.1271 8.96389C11.9534 9.13763 11.7178 9.23523 11.4721 9.23523H6.53127C6.28558 9.23523 6.04994 9.13763 5.87621 8.96389C5.70248 8.79016 5.60487 8.55453 5.60487 8.30883Z"
                fill="white"
              />
            </svg>
            <span> {{ $t("support") }}</span>
          </router-link>
        </li>
        <li @click="LogoOut">
          <div class="link">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.79936 18.8C8.79936 19.1183 8.67295 19.4235 8.44792 19.6485C8.22289 19.8736 7.91769 20 7.59945 20H1.99986C1.46946 20 0.96079 19.7893 0.585744 19.4142C0.210698 19.0391 0 18.5304 0 18V2C0 1.46957 0.210698 0.960859 0.585744 0.585786C0.96079 0.210714 1.46946 0 1.99986 0H7.59945C7.91769 0 8.22289 0.126428 8.44792 0.351472C8.67295 0.576516 8.79936 0.88174 8.79936 1.2C8.79936 1.51826 8.67295 1.82348 8.44792 2.04853C8.22289 2.27357 7.91769 2.4 7.59945 2.4H2.39983V17.6H7.59945C7.91769 17.6 8.22289 17.7264 8.44792 17.9515C8.67295 18.1765 8.79936 18.4817 8.79936 18.8ZM19.6476 9.151L15.6479 5.151C15.4225 4.92557 15.1167 4.79892 14.7979 4.79892C14.4791 4.79892 14.1734 4.92557 13.948 5.151C13.7226 5.37643 13.5959 5.68219 13.5959 6.001C13.5959 6.31981 13.7226 6.62557 13.948 6.851L15.8988 8.8H7.59945C7.28121 8.8 6.97601 8.92643 6.75098 9.15147C6.52596 9.37652 6.39954 9.68174 6.39954 10C6.39954 10.3183 6.52596 10.6235 6.75098 10.8485C6.97601 11.0736 7.28121 11.2 7.59945 11.2H15.8988L13.947 13.151C13.7216 13.3764 13.5949 13.6822 13.5949 14.001C13.5949 14.3198 13.7216 14.6256 13.947 14.851C14.1724 15.0764 14.4781 15.2031 14.7969 15.2031C15.1157 15.2031 15.4214 15.0764 15.6469 14.851L19.6466 10.851C19.7585 10.7396 19.8473 10.6072 19.908 10.4613C19.9686 10.3155 19.9999 10.1591 20 10.0012C20.0001 9.84327 19.969 9.68687 19.9085 9.54097C19.8481 9.39507 19.7594 9.26255 19.6476 9.151Z"
                fill="#fff"
              />
            </svg>
            <span> {{ $t("LogOut") }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Cookie from "cookie-universal";
const cookie = Cookie();
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  data() {
    return {
      showSon: false,
      Is_Subscription: false,
      is_company:false,
      individial:false,
      is_subscribed_to_services_package: false,
    };
  },
  methods: {
    LogoOut() {
      cookie.remove("Companydata");
      cookie.remove("Companydata");
      cookie.remove("Token");
      cookie.remove("Userdata");
      window.location.href = "/";
    },
    showSide() {
      this.$emit("Show");
    },
    GetSubscriptions() {
      axios
        .get(`/company/dashboard/subscriptions`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          if (res.data.data.Subscription.length > 0) {
            this.Is_Subscription = true;
          }
          res.data.data.Subscription.forEach((element) => {
            if (element.is_subscribed_to_services_package == true) {
              this.is_subscribed_to_services_package = true;
            }
          });
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
  },
  computed: {
    serviceActiveLink() {
      if (
        this.$route.path == "/profile/companies" ||
        this.$route.path == "/profile/companies/managing-personnel" ||
        this.$route.path == "/profile/companies/managing-roles"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if(cookie.get('Companydata')){
      this.is_company =true
      this.GetSubscriptions();
    }

    this.$store.dispatch("ServiceOrdersIndividial").then((r) => {
      if(this.$store.state.ServiceOrders.length > 0){
        this.individial =true
      }
    });

  },
};
</script>
